<template>
	<nav class="button-gallery-wrapper" role="navigation" >
		<button class="button-gallery">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<fig></fig>
		</button>
		<span>Our <strong>Artists</strong></span>
	</nav>
</template>

<script>
// Dependencies
//import { mapGetters } from 'vuex'

// Export
export default {
	name: 'buttonGallery',
	data: () => ({
		show: false
	}),
	methods: {
	},
	computed: {
	},
}
</script>

<style lang="scss" scoped>
@mixin navbar()
{
	background-color: color_('white');
	border-radius: 0px;
	display: block;
	height: 2px;
	position: absolute;
	transition: transform $transition-cubic 200ms, background-color $transition-cubic 100ms, opacity $transition-cubic 200ms;
	transform-origin: 50% 50%;
	width: 28px;
	
}

.button-gallery {
	$buttongallery: &;
	position: relative;
	height: 30px;
	width: 30px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	padding: 0;
    background: 0;
    border: 0;
	

	span {
		border: 1px solid rgb(255,255,255);
		background: rgb(255,255,255);
		height: 6px;
		width: 6px;
		margin: 2px;
		display: block;
		transition: opacity $transition-cubic 100ms;
		opacity: 1;

		#{$buttongallery}--open & {
			opacity: 0;
		}
	}

	// strong {
	// 	color: #fff;
	// 	font-weight: 200;
	// 	font-weight: ;
	// }

	fig {
		@include navbar();
		opacity: 0;
		left: 15px;
    	top: 15px;
		transform: translateY(0%) translateX(-50%);

		&:before,
		&:after {
			@include navbar();
			content: "";
		}
		&:before {
			transform: translateY(-500%);
			
		}
		&:after {
			transform: translateY(500%);
			
		}

		#{$buttongallery}--open & {
				opacity: 1;
				background-color: transparent;
				transform: translateY(-50%) translateX(-50%) rotate(180deg);

				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			
		}
	}


	&-wrapper {
		position: fixed;
		//height: 60px;
		//width: 60px;
		top: $margin;
		left: $margin;
		z-index: 33;
		display: flex;
		transition: all $transition-cubic 300ms;
		opacity: 1;

		&:hover {
			cursor: pointer;
			opacity: .5;
		}

		& > span {
			font-family: $font-secondary;
			letter-spacing: 4px;
			color: color_('white');
			line-height: 1.75;
			margin-left: 10px;

			strong {
				//margin-left: 2px;
			}
		}
	}
}
</style>
