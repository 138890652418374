<template>
	<div class="content-band__wrapper">
		<slot name="img-top"></slot>
		<div class="grid">
			<div class="grid__inner">
				<div class="grid__cell grid__cell--span-1"></div>
                <div class="grid__cell grid__cell--span-10 content-band__content">
                    <slot></slot>
                </div>
				<div class="grid__cell grid__cell--span-1"></div>
			</div>
		</div>
		<slot name="img-bottom"></slot>
	</div>
</template>

<script>
// Components

// Export
export default {
	name: 'contentBand',
}
</script>

<style lang="scss" scoped>
.content-band {

	&__wrapper {
        position: relative;
		//background: url(/public/img/black_background.jpg) #1e1e1e scroll repeat;
		background:#1e1e1e;

        .grid {
            margin: 0 auto;
			max-width: 1280px;
			@include gutter('padding-top',2);
            @include gutter('padding-bottom',1.5);
        }
	}

	&__content {
		position: relative;
		@include gutter('padding-bottom', 2.5);
		@include gutter('padding-top', 2.5);
		@include grid-cell-align('middle');

		@media screen and (max-width: map-get($breakpoints, 'md')) {
			@include gutter('padding-top', .5);
			@include gutter('padding-bottom', .5);
		}
		
		p {
			line-height: 1.5em;
			font-size: 26px;
			font-weight: 600;
			text-align: center;
			color: color_('white');
			@include gutter('margin-bottom', 1.5);

			@media screen and (max-width: map-get($breakpoints, 'lg')) {
				font-size: 20px;
			}
		}
	}

	&__title {
		color: color_('secondary');
		@include font-size('h1');
		text-align: center;
		margin-top: 0;
		@include gutter('margin-bottom', 1.2);

		@media screen and (max-width: map-get($breakpoints, 'md')) {
			font-size: 2.8rem;
		}
	}

	&__image {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		@include ratio('standard');
		min-height: 350px;

		&-short {
			@include ratio('film');
		}
	}

	&__artist-profile {
		text-align: center;
		text-transform: uppercase;
		font-family: $font-secondary;
		font-weight: 300;
		letter-spacing: 6px;
		font-size: 18px;
		color: #5189CF;
	}
}
</style>
